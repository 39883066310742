import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../../theme";
import { createNotification, getNotifications } from "../../../services/core-api";
import { MainNotification } from "../../../interfaces/core/MainNotification";

const MainNotifications = () => {
  const [mainNotifications, setMainNotifications] = React.useState<MainNotification[] | []>([]);
  const [fromDate, setFromDate] = React.useState(dayjs());
  const [toDate, setToDate] = React.useState(dayjs());
  const [description, setDescription] = React.useState('');

  React.useEffect(() => {
    const fetchMainNotification = async () => {
      try {
        const response = await getNotifications();
        if (response) {
          setMainNotifications(response);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchMainNotification();
  }, []);

 
  const handleSave = async () => {
    try {
      const newBanner = {
        description,
        from_date: fromDate ? fromDate.format("YYYY-MM-DDTHH:mm:ss") : "",
        to_date: toDate ? toDate.format("YYYY-MM-DDTHH:mm:ss") : "",
      };

      const response = await createNotification(newBanner);

      if (response) {
        setMainNotifications((prevNotifications) => [
          ...prevNotifications,
          response,
        ]);

        setDescription("");
        setFromDate(dayjs());
        setToDate(dayjs());
      }
    } catch (error) {
      console.error("Error saving notification:", error);
    }
  };

  // Date change handlers
  const handleFromDateChange = (date: Dayjs | null) => {
    if (date) {
      setFromDate(date.startOf('day'));
    }
  };

  const handleToDateChange = (date: Dayjs | null) => {
    if (date) {
      setToDate(date.endOf('day'));
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{
          fontSize: "18px",
          textAlign: "left",
          fontFamily: "Inter",
          fontWeight: 600,
          mt: 3,
          mb: 2,
          color: theme.palette.text.primary,
        }}
      >
        Daily Notifications
      </Typography>

      <Accordion defaultExpanded sx={{ boxShadow: "none", border: "1px solid #f1f1f1", mt: 2, mb: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Add new notification
        </AccordionSummary>
        <AccordionDetails>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-multiline-flexible"
                    placeholder="Description"
                    inputProps={{ maxLength: 300 }}
                    multiline
                    fullWidth
                    rows={2}
                    maxRows={2}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DatePicker
                        format="MM/DD/YYYY"
                        sx={{
                          flex: 1,
                          float: "left",
                          bgcolor: "#fff",
                          mr: 1,
                          "& .MuiOutlinedInput-root": {
                            height: "30px",
                            width: "100%",
                            borderRadius: 0,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 0,
                          },
                          "& .MuiInputBase-input": {
                            height: "30px",
                            padding: "0 14px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                        }}
                        value={fromDate}
                        onChange={handleFromDateChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <DatePicker
                        format="MM/DD/YYYY"
                        sx={{
                          flex: 1,
                          float: "left",
                          bgcolor: "#fff",
                          mr: 1,
                          "& .MuiOutlinedInput-root": {
                            height: "30px",
                            width: "100%",
                            borderRadius: 0,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 0,
                          },
                          "& .MuiInputBase-input": {
                            height: "30px",
                            padding: "0 14px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                        }}
                        value={toDate}
                        onChange={handleToDateChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    sx={{
                      width: "250px",
                      float: "left",
                      bgcolor: theme.palette.primary.dark,
                      color: theme.palette.text.secondary,
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: theme.palette.primary.dark,
                      },
                    }}
                    onClick={handleSave}
                  >
                    Add New Notification
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>
        </AccordionDetails>
      </Accordion>

      <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #f1f1f1', p: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#f1f1f1" }}>
            <TableRow>
              <TableCell sx={{ pb: 0, fontWeight: 600 }}>Message</TableCell>
              <TableCell align="right" sx={{ pb: 0, fontWeight: 600 }}>Start Date</TableCell>
              <TableCell align="right" sx={{ pb: 0, fontWeight: 600 }}>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mainNotifications.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.description}
                </TableCell>
                <TableCell align="right">{row.from_date}</TableCell>
                <TableCell align="right">{row.to_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MainNotifications;
