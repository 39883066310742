import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createBannerNotification } from '../../../services/core-api';
import theme from '../../../theme';


const BannerNotifications = () => {
    const [fromDate, setFromDate] = React.useState(dayjs());
    const [toDate, setToDate] = React.useState(dayjs());
    const [description, setDescription] = React.useState('');

    const handleSave = async () => {
        try {
            const newBanner = {
                description,
                // Add time component to match datetime format
                from_date: fromDate 
                    ? fromDate.format("YYYY-MM-DDTHH:mm:ss")  // Added time component
                    : "",
                to_date: toDate 
                    ? toDate.format("YYYY-MM-DDTHH:mm:ss")    // Added time component
                    : "",
            };

            const response = await createBannerNotification(newBanner);

            if (response) {
                window.location.reload();
            }
        } catch (error) {
            console.error('Error creating banner:', error);
        }
    };

    const handleFromDateChange = (date: Dayjs | null) => {
        if (date) {
            // Set time to start of day
            setFromDate(date.startOf('day'));
        }    
    };

    const handleToDateChange = (date: Dayjs | null) => {
        if (date) {
            // Set time to end of day
            setToDate(date.endOf('day'));
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Typography 
                            variant='h5'
                            sx={{
                                fontSize: '18px',
                                textAlign: 'left',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                color: '#000', // Example of a theme color usage
                            }}
                        >
                            Banner Notification
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            id="outlined-multiline-flexible"
                            placeholder="Description"
                            inputProps={{ maxLength: 400 }}
                            multiline
                            fullWidth
                            rows={4}
                            maxRows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DatePicker
                                    format="MM/DD/YYYY"
                                    sx={{
                                        flex: 1,
                                        float: 'left',
                                        bgcolor: "#fff",
                                        mr: 1,
                                        "& .MuiOutlinedInput-root": {
                                            height: "50px",
                                            width: '100%',
                                            borderRadius: 0,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius: 0,
                                        },
                                        "& .MuiInputBase-input": {
                                            height: "30px",
                                            padding: "0 14px",
                                            fontSize: "13px",
                                            fontFamily: "Inter",
                                        },
                                    }}
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    format="MM/DD/YYYY"
                                    sx={{
                                        flex: 1,
                                        float: 'left',
                                        bgcolor: "#fff",
                                        mr: 1,
                                        "& .MuiOutlinedInput-root": {
                                            height: "50px",
                                            width: '100%',
                                            borderRadius: 0,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius: 0,
                                        },
                                        "& .MuiInputBase-input": {
                                            height: "30px",
                                            padding: "0 14px",
                                            fontSize: "13px",
                                            fontFamily: "Inter",
                                        },
                                    }}
                                    value={toDate}
                                    onChange={handleToDateChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            sx={{
                                width: '150px',
                                float: 'left',
                                bgcolor: theme.palette.primary.dark, // Example of a theme color usage
                                color: '#fff',
                                textTransform: 'capitalize',
                                "&:hover": {
                                    bgcolor: theme.palette.primary.dark,
                                }
                            }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}

export default BannerNotifications;